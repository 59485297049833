import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import '../assets/css/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormGroup, Form, Input, Media, Button } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faKey, faAt } from '@fortawesome/free-solid-svg-icons'
import { InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL } from 'environment'

library.add(faKey, faAt)

export default class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: this.props.location.state ? this.props.location.state.message : '',
        };
    }

    onKeyPress2 = (target) => {
        if (target.charCode == 13) {
            this.signIn()
        }
    }

    signIn = () => {
        const data = { email: this.email, senha: this.senha };
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        };

        fetch(`${BASE_URL}/auth`, requestInfo)
            .then(response => {
                if (response.ok)
                    return response.json()
                throw new Error("Login inválido");
            })
            .then(json => {
                if ('valid' in json && !json.valid)
                    throw new Error(json.error);

                localStorage.setItem('token', json.token);
                this.props.history.push("/admin");
                return;
            })
            .catch(e => {
                this.setState({ message: e.message });
            });
    }

    render() {
        return (
            <div className="login-form">
                <Form onKeyPress={this.onKeyPress2}>
                    <h3 className="text-center"></h3>
                    <div>
                        <Media object src={process.env.PUBLIC_URL + '/captar.png'} />
                    </div>
                    <h3 className="text-center"></h3>
                    {
                        this.state.message !== '' ? (
                            <Alert color="danger" className="text-center"> {this.state.message} </Alert>
                        ) : ''
                    }
                    <FormGroup>
                        <InputGroup className="active active-pro" md="5">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="at" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input autoFocus type="email" id="email" onChange={e => this.email = e.target.value} placeholder="Informe seu e-mail" />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup md="5">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="key" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="password" id="senha" onChange={e => this.senha = e.target.value} placeholder="Informe a senha" />
                        </InputGroup>
                    </FormGroup>
                    <Button className="btn-lg btn-info btn-block" onClick={this.signIn}> Entrar </Button>
                </Form>
            </div>
        );
    }
}