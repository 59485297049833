import React, { Component } from 'react';
import Routes from './routes'
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.2.0";
import "assets/css/demo.css";
import ReactGA from 'react-ga';

ReactGA.initialize("UA-157763385-2")

class App extends Component {

    render() {
        return (
            <div >
                <Routes />
            </div>
        )
    }
}

export default App