import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBuilding, faPlusSquare, } from '@fortawesome/free-solid-svg-icons'


import {
    Card, CardBody, CardHeader, CardFooter,
    Row, Col, InputGroup, Input, InputGroupAddon, InputGroupText
} from "reactstrap";
import dateformat from 'dateformat'
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { BASE_URL } from "../environment"

library.add(faBuilding, faPlusSquare)

export default function Leitura() {
    const [apartamentos, setApartamentos] = useState([])
    const [termoDeBusca, setTermoDeBusca] = useState("")
    const [resultadoDeBusca, setResultadoDeBusca] = useState([])

    const handleTermoDeBusca = event => {
        setTermoDeBusca(event.target.value)
    }

    useEffect(() => {
        const results = apartamentos.filter(apartamento => apartamento.numero.toLowerCase().includes(termoDeBusca))
        setResultadoDeBusca(results)
    }, [termoDeBusca])

    const loadItems = async () => {
        const requestInfo = {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }),
        };

        fetch(`${BASE_URL}/apartamentos`, requestInfo)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(`Request rejected with status ${response.status}`);
                }
            })
            .then(json => {
                setApartamentos(json)
                setResultadoDeBusca(json)
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    useEffect(() => {
        loadItems();
    }, [])

    return (
        <>
            {/* <PanelHeader size="lg" /> */}
            <PanelHeader size="md" />
            <div className="content">
                <Search onChange={handleTermoDeBusca} />
                <Apartamentos apartamentos={resultadoDeBusca} />
            </div>
        </>
    )
}

function Search({ onChange }) {
    return (
        <Row>
            <Col >
                <Card>
                    <CardBody>
                        <InputGroup className="no-border active active-pro">
                            <Input placeholder="Pesquisar..." onChange={onChange} />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_zoom-bold" />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

function Apartamentos({ apartamentos }) {
    return (
        <Row>
            {apartamentos.map(apartamento => (<Col md={4} ><Apartamento apartamento={apartamento} /> </Col>))}
        </Row>
    )
}

function Apartamento({ apartamento }) {
    return (
        <Card>
            <CardHeader>
                <FontAwesomeIcon icon="building" />
                <span> {apartamento.condominio} #{apartamento.numero}</span>
            </CardHeader>
            <CardBody>
                <div>
                    <div> Medidor: {apartamento.medidor} </div>
                    <div> Última leitura: {apartamento.valorUltimaLeitura} </div>
                </div>
            </CardBody>
            <CardFooter>
                <hr />
                <div className="stats">
                    <i className="now-ui-icons loader_refresh" />
                    Lido em: {dateformat(apartamento.dataUltimaLeitura, "dd/mm/yy")}
                </div>
            </CardFooter>
        </Card>
    )
}