import React, { useEffect } from 'react'
import { Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory';
import PrivateRoute from './auth';
import Login from "./views/Login"
import AdminLayout from "./layouts/Admin";
import Logout from './views/Logout'
import Leitura from './views/Leitura'

const history = createHistory()
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

const Routes = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  })

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <PrivateRoute path="/leitura" component={Leitura} />
        <Route path="*" component={() => "404 Not Found"}></Route>
      </Switch>
    </Router>
  )
}

export default Routes;